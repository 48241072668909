import ajax from "./ajax"

// 注册
export function savaCompany(data){
    return ajax({
        url:'/api/company/savaCompany',
        method:'post',
        data
    })
}


//验证码
export function sendCode(params){
    return ajax({
        url:'/api/sms/sendCode',
        method:'get',
        params
    })
}


//基本信息提交
export function updateCompany(params){
    return ajax({
        url:'/api/company/updateCompany',
        method:'put',
        params
    })
}